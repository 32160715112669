body{
  background-color: #468f8a;
  color: #121212;
  margin: 0;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
@import url(https://fonts.googleapis.com/css?family=Crimson+Text:regular,italic,600,600italic,700,700italic);

#about .em {
  /* font-family:'Crimson Text'; */
  font-weight: bold;
}

.em {
  /* font-style: italic; */
}



#backGround {
  position: fixed;
  z-index: -100;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  opacity: 60%;
  background-image: url('../public/bg.png');


} 

.bgContainer {
  background-size: cover; 
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 100vh;
} 

.section1, .section2, .section3 {
  background-size: cover;
} 

.section2 {
  height: 30%;
  background-image: url('../public/bg.png');
  opacity: 10%;

}
.section3 {
  height: 20%;
  background-image: url('../public/bg.png');
  opacity: 10%;
} 
.section1 {
  height: 10%;
  background-image: url('../public/bg.png');
  opacity: 100%;

} 
.section4 {
  height: 100%;
  background-image: url('../public/bg.png');
  opacity: 25%;

} 


#icons {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 1%;
  font-size: 3rem;
  padding: 1%;
}

.navbar {
  text-align: center;
  background-color: #04364A;
  position: fixed;
  top: 0;
  left:0;
  width: 100%;
  height: 4rem;
  margin: 0;
  z-index: 100;
  text-decoration: none;
  line-height: 1.3;
    /* animation: nav auto linear both;
    animation-timeline: scroll();
    animation-range: 0% 50px; */
  box-shadow: 0 0 4px 2px #121212;
}


.navbar-brand, .navbar-brand:visited, .navbar-brand:active {
  text-decoration: none;
  color: #DAFFFB;
  font-size: 1.5rem;
  transition: color .3s;
}

.navbar-brand:hover{
  color: #e3a248;
}

.navbar-nav .nav-link, .navbar-nav .nav-link:visited, .navbar-nav .nav-link:active {
  color: #DAFFFB;
  font-size: 1.3rem;
  padding: 0.5rem 2%;
  margin: auto;
  cursor: pointer;
  text-decoration: none;
}

.navbar-nav .nav-link:hover {
  color: #e3a248; 
}




#home {
  background-color: #176B87;
  color: #DAFFFB;
  padding: 50px 20px;
  text-align: center;
  margin: auto; 
  border-radius: 5px;
  box-shadow: 0 0 6px 3px #121212;
  width: 85%;
  position: relative;
  animation: heading 1s;
  animation-timing-function: ease-out;


}


#about {
  background-color: #DAFFFB;
  width: 85%;
  margin: 40px auto;
  padding: 20px;
  box-shadow: 0 0 8px 2px #121212;
  border-radius: 5px;
  position: relative;
  animation: sections 1s;
  animation-timing-function: ease-out;

}

#about .aboutMeParagraph {
  text-indent: 40px;
  line-height: 1.5;
}

#about h2 {
  text-align: center;
}

#employment {
  border-radius: 5px;
  box-shadow: 0 0 8px 2px #121212;
  padding: 20px;
  width: 85%;
  background-color: #DAFFFB;
  margin: auto;
  position: relative;
  animation: sections 1s;
  animation-timing-function: ease-out;
  margin-bottom: 40px;

}


#employmentSectionTop {
  margin-bottom: 50px;
}

#employment h2 {
  font-size: 2em;
  text-align: center;
}

#employment h3 {
  line-height: .5;
  position: relative;
  top: -70px;
}
#employment h4, h5 {
  line-height: 0;
}
#employment h5 {
  text-indent: 10px;
}

#employment span {
  position: relative;
  top:-55px;
} 

#projects .slick-dots {
  bottom: 10px;
}

#projects .slick-dots:active {
  color: #ffc107;
}

#msLogo {
  width: 90px;
  border-radius: 5px;
  position: relative;
  top: -70px;
}
#tsgLogo {
  width: 90px;
  border-radius: 5px;
  position: relative;
  top: -70px;
}

#employment p {
  text-indent: 40px;
  line-height: 1.5;
}

#skills {
  border-radius: 5px;
  box-shadow: 0 0 8px 2px #121212;
  padding: 20px;
  width: 85%;
  background-color: #DAFFFB;
  margin: auto;
  position: relative;
  animation: sections 1s;
  animation-timing-function: ease-out;
  margin-bottom: 40px;

}
#skills h2 {
  font-size: 2em;
  text-align: center;
}

#skills p {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
}
.skills{
  background-color: #e3a248;
  padding: 4px;
  border-radius: 10px;
  margin: 2px;
}

#contact {
  padding: 80px 20px;
  background-color: #176B87;
  color: #DAFFFB;
  margin-top: 56px; 
  width: 85%;
  border-radius: 5px 5px 0 0;
  box-shadow: 0 0 6px 3px #121212;

  margin: 50px auto 0 auto;
  position: relative;
  animation: sections 1s;
  animation-timing-function: ease-out;

}

#contact p {
  color: #e3a248;
}

footer {
  text-align: center;
  background-color: #04364A;
  color: #DAFFFB;
  padding: 20px 0;
  box-shadow: 0 0 4px 2px #121212;
  

  /* height: 4rem; */
}

.contact-icons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.contact-icons a {
  display: inline-block;
  margin: 10px; 
  color: #DAFFFB;
  transition: color 0.3s;
}

.contact-icons a:hover {
  color: #e3a248; 
}
.icon-container a {
  font-size: 30px;
}



.navbar-nav .nav-link:hover {
  color: #e3a248; 
}

#projects {
  text-align: center;
  
}

.project-card {
  height: 75vh;
  
  background-color: #DAFFFB;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0 0 8px 2px #121212;
  width: 85%;
  margin: auto;
  position: relative;
  animation: sections 1s;
  animation-timing-function: ease-out;
  margin-bottom: 40px;
  margin-top: 10px;



}
#projects{
  margin-bottom: 40px;
}



.project-card img{
  border: 1px solid #121212;
  border-radius: 5px;
}




.project-card a{
  display: block;
  margin-bottom: 10px;
}


.project-image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60%;
}

.project-image img {
  /* width: 20em; */
  max-width: 50%;
  max-height: 100%;
}
.custom-prev-arrow{
  padding-right: 50px;
}

.custom-next-arrow{
  padding-left: 50px;
}

.custom-prev-arrow,
.custom-next-arrow {
  display: flex;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 5%;
  height: 10%;
  color: #121212;
  justify-content: end;
  font-size: 2em;
  cursor: pointer;
  border-radius: 50%;
  z-index: 1;
}

.custom-prev-arrow {
  left: 0;
}

.custom-next-arrow {
  right: 0;
}

.custom-prev-arrow:hover,
.custom-next-arrow:hover {
  color: #e3a248;
}

.icon-container {
  display: flex;
  align-items: center;
}

/* .icon-container a {
  margin-right: 10px; 
} */

.icon1,.icon2,.icon3,.icon4,.icon5,.icon6,.icon7,.icon8{
  padding: 10px;
  height: .75em;
  width: .75em;
  background-color: #e3a248;
  border-radius: 50%;
  color: #176B87;
}

.icon8{
  animation: icons .25s 1;
  animation-delay: .75s;
  animation-timing-function: ease-in-out;
}

.icon7{
  animation: icons .5s 1;
  animation-delay: .75s;
  animation-timing-function: ease-in-out;

}
.icon6{
  animation: icons .75s 1;
  animation-delay: .75s;
  animation-timing-function: ease-in-out;


}
.icon5{
  animation: icons 1s 1;
  animation-delay: .75s;
  animation-timing-function: ease-in-out;


}
.icon4{
  animation: icons 1.25s 1;
  animation-delay: .75s;
  animation-timing-function: ease-in-out;


}
.icon3{
  animation: icons 1.5s 1;
  animation-delay: .75s;
  animation-timing-function: ease-in-out;


}
.icon2{
  animation: icons 1.75s 1;
  animation-delay: .75s;
  animation-timing-function: ease-in-out;


}
.icon1{
  animation: icons 2s 1;
  animation-delay: .75s;
  animation-timing-function: ease-in-out;
}

/* @supports (animation-timeline: view()){
  @keyframes nav {
    from {
      opacity: 0%;

    }
    to {
      opacity: 100%;
    }

  }
} */


@keyframes heading {
  from {
    transform: translateY(-100%);
    z-index: -1;


  }
  to {
    transform: translateY(0%);
    z-index: 1;

  }
}

@keyframes sections {
  from {
    transform: translateY(100%);
    z-index: -1;

  }
  to {
    transform: translateY(0%);
    z-index: 1;

  }
}

@keyframes icons {
  from {
    transform: translateX(0%);
  }



  50% {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0%);
  }
}

@media screen and (max-width: 1250px) {
  #employment h2 {
    text-align: right;
    font-size: 1.25rem;
  }
  .project-card{
    height: 65vh;

  }
  .project-card img {
    /* width: 13em; */
  }


} 

@media screen and (max-width: 650px) {
  .icon1,.icon2,.icon3,.icon4,.icon5,.icon6,.icon7,.icon8{
    font-size: .8em;
    margin: 2px;

  }

  #contact p{
    font-size: .85em;
  }
  .navbar-nav .nav-link, .navbar-nav .nav-link:visited, .navbar-nav .nav-link:active {
    font-size: 1.1rem;
    margin-top: 190px;
    padding: 0.5rem 2%;
  }

}


